import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { saveMateria } from "../../services/MateriaService";
import { getProcesos } from "../../services/ProcesoService";
import darkTheme from "../../layout/DarkTheme";

const initialState = {
  descripcion: "",
  descripcionCorta: "",
  solicitaMonto: false,
  visibilidad: "",
  observaciones: "",
};

const visibilidadOptions = [
  { id: "PUBLICO", descripcion: "Público" },
  { id: "NO-PUBLICO", descripcion: "No público" },
];

const itemGender = "F";
const nameItemText = "Materia";

//
// #####################################################################################################################
//
const MateriaDialog = ({
  fueros,
  onSave,
  onClose,
  itemCrud,
  actionCrud,
  setMessage,
  setShowMessage,
}) => {
  const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
  const [isDoingSomething, setIsDoingSomething] = useState(false);
  const [procesosLocal, setProcesosLocal] = useState([]);
  const [valueAutoCompleteProcesos, setValueAutoCompleteProcesos] =
    useState(itemCrud.proceso);
  const [
    inputValueAutoCompleteProcesos,
    setInputValueAutoCompleteProcesos,
  ] = useState("");
  const [valueAutoCompleteFuero, setValueAutoCompleteFuero] = useState(
    itemCrud.fuero
  );
  const [inputValueAutoCompleteFuero, setInputValueAutoCompleteFuero] = useState("");

  useEffect(() => {
    if (actionCrud === "create") {
      setValueAutoCompleteFuero("");
      setItemCrudLocal(initialState);
    } else {
      setValueAutoCompleteFuero(itemCrud.fuero);
      setValueAutoCompleteProcesos(itemCrud.proceso);
      setItemCrudLocal(itemCrud);
      loadProcesos(itemCrud.fuero);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setItemCrudLocal({
      ...itemCrudLocal,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleFueroChange = (event, newValue) => {
    setValueAutoCompleteFuero(newValue);
    setValueAutoCompleteProcesos("");
    loadProcesos(newValue);
  };

  const loadProcesos = async (fuero) => {
    let _params = {
      idFuero: fuero?.id || valueAutoCompleteFuero?.id,
    };
    setIsDoingSomething(true);

    const response = await getProcesos(_params);
    setIsDoingSomething(false);
    if (response.ok) {
      let _response = response.data.data;
      let _procesos = [];
      _response.forEach((item) => {
        _procesos.push({
          id: item.id,
          label: item.descripcion,
        });
      });
      setProcesosLocal(_procesos);
    } else {
      setProcesosLocal([]);
      setMessage({
        messageText: response.messageText,
        severity: response.messageSeverity,
      });
      setShowMessage(true);
    }
  };

  const handleSave = () => {
    if (actionCrud === "create" || actionCrud === "update") {
      if (inputValueAutoCompleteProcesos === "") {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar una Proceso",
        });
        setShowMessage(true);
        return;
      }

      if (itemCrudLocal.descripcion.trim() === "") {
        setMessage({
          severity: "error",
          messageText: "Debes ingresar una descripción",
        });
        setShowMessage(true);
        return;
      }
      if (itemCrudLocal.visibilidad === "" || itemCrudLocal.visibilidad === undefined) {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar visibilidad",
        });
        setShowMessage(true);
        return;
      } 
      if (itemCrudLocal.solicitaMonto === "" || itemCrudLocal.solicitaMonto === undefined) {
        setMessage({
          severity: "error",
          messageText: "Debes seleccionar si solicita monto",
        });
        setShowMessage(true);
        return;
      } 
    }

		if (actionCrud === "create") {
      if (itemCrudLocal.descripcionCorta.trim() === "") {
        setMessage({
          severity: "error",
          messageText: "Debes ingresar una descripción corta",
        });
        setShowMessage(true);
        return;
      }
    }

    save();
  };

  const save = async () => {
    let _itemCrud = {
      idProceso: valueAutoCompleteProcesos.id,
      descripcion: itemCrudLocal.descripcion,
      visibilidad: itemCrudLocal.visibilidad,
      solicitaMonto: itemCrudLocal.solicitaMonto,
      observaciones: itemCrudLocal.observaciones,
      usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
    };
    if (actionCrud !== "create") {
      _itemCrud.id = itemCrudLocal.id;
    }
		if (actionCrud === "create") {
			_itemCrud.descripcionCorta = itemCrudLocal.descripcionCorta;
		}

    setIsDoingSomething(true);
    const response = await saveMateria(actionCrud, _itemCrud);
    setIsDoingSomething(false);

    if (response.ok) {
      onSave();
    }

    setMessage({
      messageText: response.messageText,
      severity: response.messageSeverity,
    });
    setShowMessage(true);
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          textAlign={{ xs: "center", sm: "center" }}
          style={{
            background: darkTheme.palette.primary.dark,
            color: darkTheme.palette.primary.text,
          }}
        >
          {actionCrud === "create"
            ? itemGender === "M"
              ? "Nuevo"
              : "Nueva"
            : actionCrud === "update"
            ? "Editando"
            : actionCrud === "enable"
            ? "Habilitando"
            : actionCrud === "read"
            ? "Detalle"
            : "Deshabilitando"}{" "}
          {nameItemText}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {isDoingSomething && <LinearProgress />}

        <DialogContent dividers>
          <Box sx={{ m: 1, p: 1 }}>
            <Autocomplete
              sx={{ width: "100%", pb: 1 }}
              size="small"
              value={valueAutoCompleteFuero}
              onChange={handleFueroChange}
              inputValue={inputValueAutoCompleteFuero}
              onInputChange={(event, newInputValue) => {
                setInputValueAutoCompleteFuero(newInputValue);
              }}
              id="fuero"
              options={fueros}
              renderInput={(params) => <TextField {...params} label="Fuero" />}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
            />
            <Autocomplete
              id="proceso"
              sx={{ width: "100%", pb: 1 }}
              size="small"
              value={valueAutoCompleteProcesos}
              onChange={(event, newValue) => {
                setValueAutoCompleteProcesos(newValue);
              }}
              inputValue={inputValueAutoCompleteProcesos}
              onInputChange={(event, newInputValue) => {
                setInputValueAutoCompleteProcesos(newInputValue);
              }}
              options={procesosLocal}
              renderInput={(params) => (
                <TextField {...params} label="Proceso" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read" ||
                !valueAutoCompleteFuero?.id
              }
            />

            <TextField
              variant="outlined"
              size="small"
              name="descripcion"
              label="Descripción"
              value={itemCrudLocal.descripcion}
              onChange={handleChange}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
              sx={{ width: "100%", pb: 1 }}
              inputProps={{ maxLength: 80 }}
            />

            <TextField
              variant="outlined"
              size="small"
              name="descripcionCorta"
              label="Descripción corta"
              value={itemCrudLocal.descripcionCorta}
              onChange={handleChange}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "update" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
              sx={{ width: "100%", pb: 1 }}
              inputProps={{ maxLength: 80 }}
            />

            <FormControl
              sx={{ width: "100%", pb: 1 }}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
            >
              <InputLabel id="visibilidad-select-label">Visibilidad</InputLabel>
              <Select
                sx={{ width: "100%", mr: 1 }}
                labelId="visibilidad"
                label="Visibilidad"
                id="visibildad"
                name="visibilidad"
                value={itemCrudLocal.visibilidad}
                size="small"
                onChange={handleChange}
              >
                {visibilidadOptions.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.descripcion}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box display="flex" alignItems="center" ml={2}>
              <InputLabel variant="h8" component="div" gutterBottom>
                Solicita monto
              </InputLabel>
              <Box display="flex" alignItems="center" ml={2}>
                <Checkbox
                  name="solicitaMonto"
                  checked={itemCrudLocal.solicitaMonto}
                  onChange={handleChange}
                  disabled={
                    actionCrud === "disable" ||
                    actionCrud === "enable" ||
                    actionCrud === "read"
                  }
                  sx={{ pb: 1 }}
                />
              </Box>
            </Box>

            <TextField
              variant="outlined"
              size="small"
              name="observaciones"
              label="Observaciones"
              value={itemCrudLocal.observaciones}
              onChange={handleChange}
              disabled={
                actionCrud === "disable" ||
                actionCrud === "enable" ||
                actionCrud === "read"
              }
              sx={{ width: "100%", pb: 1 }}
              inputProps={{ maxLength: 80 }}
            />

            {actionCrud !== "create" && (
              <Box
                sx={{
                  p: 1,
                  mt: 1,
                  color: "SlateGrey",
                  border: "0px solid DarkGray",
                  borderRadius: "3px",
                }}
              >
                <Typography variant="h8" component="div" gutterBottom>
                  Creado: {itemCrudLocal.fechaCreacion}
                </Typography>

                <Typography variant="h8" component="div" gutterBottom>
                  Última actualización: {itemCrudLocal.fechaActualizacion}
                </Typography>

                <Typography variant="h8" component="div" gutterBottom>
                  Último usuario que actualizó: {itemCrudLocal.nombreUsuario}{" "}
                  {itemCrudLocal.apellidoUsuario} ({itemCrudLocal.username})
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={{ textAlign: "right" }}>
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              onClick={handleCloseDialog}
              startIcon={<CloseIcon />}
            >
              Cancelar
            </Button>

            {actionCrud !== "read" && (
              <Button
                sx={{ mr: 1, backgroundcolor: "warning" }}
                variant="contained"
                onClick={handleSave}
                startIcon={
                  actionCrud === "disable" ? (
                    <RemoveCircleOutlineIcon />
                  ) : actionCrud === "enable" ? (
                    <AddCircleOutlineIcon />
                  ) : (
                    <SaveIcon />
                  )
                }
              >
                {actionCrud === "disable"
                  ? "Deshabilitar"
                  : actionCrud === "enable"
                  ? "Habilitar"
                  : "Guardar"}
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MateriaDialog;
